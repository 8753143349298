import React from "react";

export default function MarketView(props) {
  const {
    data,
    pindex,
    userRole,
    marketStatusChange,
    marketShowHideStatusChange,
    liveData,
    getValueForKhai,
    getValueForLagai,
    fetchMarketPosition,
    showAnnouncementRow,
    announcement,
    handleChange,
    updateMarketAnnouncement,
    clearMarketAnnouncement,
    setToggleBook
  } = props;
  return (
    <div className="row scroll-form" key={pindex}>
      {(data.market_type == 6 || data.market_type == 7) && (
        <div
          className="bet-place-tbl-th align-left"
          style={{ backgroundColor: "#6E8FC9" }}
        >
          <div className="d-flex justify-between flex-center">

            <div style={{ display: 'flex' }}>
              {(userRole?.role == 1 || userRole?.role == 8) &&
                <div className="switch-container" style={{ marginRight: '5px' }} title="on/off for clients" >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={data.hide_market == 1 ? false : true}
                      onChange={(e) =>
                        marketShowHideStatusChange(
                          e,
                          data.market_id,
                          data.hide_market,

                        )
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </div>}
              <p className="p-0">{data.market_name}</p>
            </div>

            {(userRole?.role == 1 || userRole?.role == 8) &&
              data.market_type >= 5 ? (
              <label className="switch">
                <input
                  type="checkbox"
                  checked={data.betLockStatus}
                  onChange={(e) =>
                    marketStatusChange(
                      e,
                      data.market_id,
                      "", // no need to pass selection id
                      e.target.checked
                    )
                  }
                />
                <span className="slider round"></span>
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <div
        className={
          data.market_type == 6 || data.market_type == 7 ? "overflow-table" : ""
        }
      >
        <table className="table table-bordered table-responsive">
          <thead>
            {!(data.market_type == 6 || data.market_type == 7) && (
              <tr disabled>
                <th
                  colSpan="4"
                  className="bet-place-tbl-th align-left"
                  style={{ backgroundColor: "#6E8FC9" }}
                >
                  <div className="d-flex justify-between flex-center">
                    {/* <p className="p-0">Market : {data.market_name}</p> */}
                    <div style={{ display: 'flex' }}>
                      {(userRole?.role == 1 || userRole?.role == 8) &&
                        <div className="switch-container" style={{ marginRight: '5px' }} title="on/off for clients" >
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={data.hide_market == 1 ? false : true}
                              onChange={(e) =>
                                marketShowHideStatusChange(
                                  e,
                                  data.market_id,
                                  data.hide_market,

                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>}
                      <p className="p-0">{data.market_name}</p>
                    </div>

                    {(userRole?.role == 1 || userRole?.role == 8) &&
                      data.market_type >= 5 ? (
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={data.betLockStatus}
                          onChange={(e) =>
                            marketStatusChange(
                              e,
                              data.market_id,
                              "", // no need to pass selection id
                              e.target.checked
                            )
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    ) : (
                      <></>
                    )}
                    <div style={{ textAlign: "end" }}>
                      <button
                        className="btn btn-primary mr-3"
                        onClick={() =>
                          setToggleBook(data)
                        }
                      >
                        Book
                      </button>
                      {(Number(userRole?.role) === 1) &&
                        <button className="btn btn-primary" onClick={() => showAnnouncementRow(data)}>
                          Announcement
                        </button>
                      }
                    </div>
                  </div>

                </th>
              </tr>
            )}
            <tr>
              <th
                className="bet-place-tbl-th"
                style={{ backgroundColor: "#49494A" }}
              >
                RUNNER
              </th>
              {data.market_type == 6 || data.market_type == 7 ? (
                data?.runners?.length &&
                data?.runners.map((val, index) => (
                  <th className="bet-odds-type bet-place-tbl-th" key={index}>
                    {val?.runnerName}
                  </th>
                ))
              ) : (
                <>
                  <th className="bet-odds-type bet-place-tbl-th">LAGAI</th>
                  <th className="bet-odds-type bet-place-tbl-th">KHAI</th>
                </>
              )}
              {data.market_type == 6 || data.market_type == 7 ? (
                data?.runners?.length &&
                data?.runners.map((val, index) => (
                  <th
                    className="bet-place-tbl-th"
                    style={{ backgroundColor: "#49494A" }}
                    key={index}
                  >
                    {val?.runnerName} POSITION
                  </th>
                ))
              ) : (
                <th
                  className="bet-place-tbl-th"
                  style={{ backgroundColor: "#49494A" }}
                >
                  POSITION
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.runners && data.runners.length ? (
              data.market_type == 6 || data.market_type == 7 ? (
                <tr>
                  <td
                    data-runner-sid="Essex _403636"
                    data-column="runner_name"
                    className="bet-place-tbl-td"
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    {/* {data.market_name} */}
                    <div style={{ display: 'flex' }}>
                      {(userRole?.role == 1 || userRole?.role == 8) &&
                        <div className="switch-container" style={{ marginRight: '5px' }} title="on/off for clients" >
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={data.hide_market == 1 ? false : true}
                              onChange={(e) =>
                                marketShowHideStatusChange(
                                  e,
                                  data.market_id,
                                  data.hide_market,

                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>}
                      <p className="p-0">{data.market_name}</p>
                    </div>
                  </td>
                  {data.runners && data.runners.length
                    ? data.runners.map((val, index) => (
                      <td
                        style={{ minWidth: "30px" }}
                        key={index}
                        data-runner-sid="Essex _403636"
                        data-column="back"
                        className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                        odd-val="1.01"
                        market-id="480666"
                        runner-id="946451"
                        match-id="403636"
                        data-id="LAGAI"
                        id="Match Winner_403636_back_Essex _403636"
                      >
                        {getValueForLagai(data, val, index, liveData)}
                      </td>
                    ))
                    : null}
                  {data.runners && data.runners.length
                    ? data.runners.map((val, index) => (
                      <td
                        key={index}
                        data-runner-sid="Essex _403636"
                        data-column="position"
                        className="bet-place-tbl-td  bet-val-cent"
                        data-position="33595.26"
                        style={{
                          color:
                            fetchMarketPosition(
                              data.market_id,
                              val.selectionId
                            ) >= 0
                              ? "#228C22"
                              : "#FF0000",
                        }}
                      >
                        {fetchMarketPosition(data.market_id, val.selectionId)}
                      </td>
                    ))
                    : null}
                </tr>
              ) : (
                data.runners.map((val, index) => (
                  <tr key={index}>
                    <td
                      data-runner-sid="Essex _403636"
                      data-column="runner_name"
                      className="bet-place-tbl-td"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {
                        // role && role == "1"
                        (userRole?.role == 1 || userRole?.role == 8) &&
                          data.market_type < 5 ? (
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={!val.lock_team_betting}
                              onChange={(e) =>
                                marketStatusChange(
                                  e,
                                  data.market_id,
                                  val.selectionId,
                                  val.lock_team_betting
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        ) : null
                      }{" "}
                      {val.runnerName}
                    </td>
                    {data.market_type !== 6 && data.market_type !== 7 ? (
                      <>
                        {data?.market_type == 2 &&
                          data?.match_status != 0 &&
                          data?.match_status != "ACTIVE" ? (
                          <td
                            style={{
                              backgroundColor: "rgba(51, 51, 51, 0.5)",
                            }}
                            colSpan={2}
                            data-runner-sid="Essex _403636"
                            data-column="lay"
                            className="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                            odd-val="1.02"
                            market-id="480666"
                            runner-id="946451"
                            match-id="403636"
                            data-id="KHAI"
                            id="Match Winner_403636_lay_Essex _403636"
                          >
                            {data?.match_status}
                          </td>
                        ) : (
                          <>
                            <td
                              data-runner-sid="Essex _403636"
                              data-column="back"
                              className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                              odd-val="1.01"
                              market-id="480666"
                              runner-id="946451"
                              match-id="403636"
                              data-id="LAGAI"
                              id="Match Winner_403636_back_Essex _403636"
                            >
                              {getValueForLagai(data, val, index, liveData)}
                            </td>
                            <td
                              data-runner-sid="Essex _403636"
                              data-column="lay"
                              className="MarketTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer "
                              odd-val="1.02"
                              market-id="480666"
                              runner-id="946451"
                              match-id="403636"
                              data-id="KHAI"
                              id="Match Winner_403636_lay_Essex _403636"
                            >
                              {getValueForKhai(data, val, index, liveData)}
                            </td>
                          </>
                        )}
                      </>
                    ) : (
                      <td
                        data-runner-sid="Essex _403636"
                        data-column="back"
                        className="MarketTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                        odd-val="1.01"
                        market-id="480666"
                        runner-id="946451"
                        match-id="403636"
                        data-id="LAGAI"
                        id="Match Winner_403636_back_Essex _403636"
                      >
                        {getValueForLagai(data, val, index, liveData)}
                      </td>
                    )}
                    <td
                      data-runner-sid="Essex _403636"
                      data-column="position"
                      className="bet-place-tbl-td  bet-val-cent"
                      data-position="33595.26"
                      style={{
                        color:
                          fetchMarketPosition(
                            data.market_id,
                            val.selectionId
                          ) >= 0
                            ? "#228C22"
                            : "#FF0000",
                      }}
                    >
                      {fetchMarketPosition(data.market_id, val.selectionId)}
                    </td>
                  </tr>
                ))
              )
            ) : null}
          </tbody>
          <tfoot>
            <tr>
              {(Number(userRole?.role) === 1) ?
                <th colSpan={4} className={(data.announcement ? " " : announcement.showAnnouncementRowId && announcement.showAnnouncementRowId == data.market_id ? " " : "d-none")}>
                  <input
                    readOnly={!data.announcement ? false : true}
                    id="announcement"
                    className="announcement_input px-1 marquee"
                    type="text"
                    name="announcement"
                    value={!data.announcement ? announcement.announcement : data.announcement}
                    onChange={(e) => handleChange(e)}
                    placeholder="enter message"
                    autoComplete="off"
                  />
                  {!data.announcement ?
                    <button className='update_announcement_btn btn btn-primary btn-xs' onClick={() => updateMarketAnnouncement(data)}>Update</button>
                    :
                    <button className='close_announcement_btn btn btn-danger btn-xs' onClick={() => clearMarketAnnouncement(data)}>Close</button>
                  }
                </th>
                :
                data.announcement ?
                  <th colSpan={4} className="marquee">
                    <marquee behavior="" direction="">{data.announcement}</marquee>
                  </th> : null
              }
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
